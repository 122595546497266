import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />

    <h2 className="heading">About</h2>

    <h3 className="subheading">
      The Devotion of the Talibongnons of United States and Canada to the
      Blessed Trinity
    </h3>

    <p>
      The Church celebrates the Solemnity of the Most Blessed Trinity on the
      Sunday after the Pentecost. In Talibon, for some historical reasons, the
      fiesta of the Santisima Trinidad is traditionally celebrated on the last
      Saturday of May.
    </p>

    <p>
      Every month of May, the Talibongnons of USA and Canada gather together as
      One Family to celebrate the fiesta of the Most Blessed Trinity. This
      annual celebration started in May 1998 in New Jersey, when Dongon
      Evangelista and Ramie Flores gathered a handful of faithful Talibongnon
      devotees from USA and Canada. They gathered in different houses during
      nine Saturdays in the New Jersey / New York area to pray the nine days of
      the Novena. On the 9th day, a Mass and a fiesta were held in honor of the
      Most Blessed Trinity.
    </p>

    <p>
      In May 2000, given the "clamor" of the Talibongnons in other area to also
      host the fiesta, the celebration started to be moved from one state to
      another, from one place to another, including Talibon. Talibongnons acting
      as hermanos and hermanas would "bid" to host the annual fiesta. Becoming
      an "hermano or hermana" is voluntary, and luckily every year there is
      always a Talibongnon who sincerely volunteers to host the next fiesta
      celebrations.
    </p>

    <p>
      Since the year 2000 until now, Msgr. Bernardito "Barney" Cleopas Auza, now
      Archbishop, has been the main celebrant of the Holy Eucharist to celebrate
      the Solemnity of the Most Blessed Trinity.
    </p>

    <p>
      If has been avowed by many that the most adored Patron of the Talibongnons
      performs "miracles" to many faithful devotees.
    </p>
  </Layout>
)

export default AboutPage
